import React from "react"
import { Icon } from "ui"
import { ButtonText, ButtonWrapper, IconText } from "./style"

const Card = ({ uri, title, bg }) => {
  return (
    <ButtonWrapper to={uri} bg={bg}>
      <IconText>
        <Icon name="leaf" />
        <ButtonText>{title}</ButtonText>
      </IconText>
      <Icon name="arrow-right" size={16} />
    </ButtonWrapper>
  )
}

const SidebarButtons = ({ data }) => {
  return (
    <>
      {data.map((item, index) => {
        const colorRule = index % 3
        return (
          <Card
            {...item}
            bg={
              colorRule === 0
                ? "#00A0A1"
                : colorRule === 1
                ? "#163C67"
                : colorRule === 2
                ? "#DC5F1F"
                : "#00A0A1"
            }
          />
        )
      })}
    </>
  )
}

export default SidebarButtons
