import React from "react"

import { InternalNavContainer } from "./style"

const InternalNav = ({ children }) => {
  return (
    <InternalNavContainer>
      {children}
    </InternalNavContainer>
  )
}

export default InternalNav
