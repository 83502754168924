import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "ui/Button"
import { MainColumn } from "ui/MainColumn"

export const ContactPageContainer = styled(MainColumn)`
  padding-bottom: 40px;
  border-bottom: 2px solid #e5e5e5;
  @media ${device.desktop} {
    padding-bottom: 80px;
  }
`

export const ContactFormContainer = styled.div``

export const ContactFormText = styled.h3`
  margin-bottom: 20px;
`

export const Form = styled.form`
  display: ${(props) => props.submitted && "none"};
`
export const SubmitButton = styled(Button)``

/* Contact Us Card */

export const ContactUsWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 2px;
  border-bottom: 5px solid #00a0a1;
  padding: 23px 29px;
  margin-top: 15px;

  h5 {
    font-size: 18px;
    margin-bottom: 17px;

    @media ${device.desktop} {
      font-size: 20px;
    }
  }

  @media ${device.desktop} {
    padding: 27px;

    p:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }
`

export const ContactUsDetail = styled.p`
  display: flex;
  word-break: break-all;
  margin-top: 9px;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  @media ${device.desktop} {
    margin-top: 0px;
  }

  > * {
    height: 100%;
  }

  strong {
    margin-right: 6px;
  }

  a {
    :hover {
      text-decoration: underline;
    }
  }
`
