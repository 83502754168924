import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Contact } from "screens/Contact"

const contact = ({ data }) => {
  const { seo, featuredImage, title, content } = data.page

  return (
    <Layout internal pageContext={data.page}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <Contact data={data.page} settings={data.wp} content={content} />
    </Layout>
  )
}

export default contact

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
        hdDescription
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      pageSettings {
        relatedPages {
          ... on WpPage {
            title
            excerpt
            uri
          }
        }
      }
    }
  }
`
