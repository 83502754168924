import React, { useState } from "react"

import { useForm } from "react-hook-form"
import { TextInput, DEFAULT_ERROR_MESSAGES } from "ui/Form"
import { FormFieldsWrapper } from "ui/Form/style"
import { Icon, Box, Text, Button } from "ui"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
const CREATE_CONTACT_ENQUIRY = gql`
  mutation CreateContactEnquiry(
    $fullName: String!
    $email: String!
    $phone: String!
    $message: String!
    $type: String!
    $pqe: String!
    $areas: String!
    $subject: String!
  ) {
    createContactEnquiry(
      input: {
        clientMutationId: "Generic contact"
        fullName: $fullName
        email: $email
        phone: $phone
        message: $message
        type: $type
        pqe: $pqe
        areas: $areas
        subject: $subject
      }
    ) {
      data
      success
    }
  }
`

const Form = ({ variant, setSubmitted, submitted }) => {
  const [createContactEnquiry] = useMutation(CREATE_CONTACT_ENQUIRY)

  const FORM_SHAPE = Joi.object({
    fullName: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: Joi.string().required(),
    message: Joi.string().required(),
    pqe:
      variant === "contact" ? Joi.string().allow("") : Joi.string().required(),
    areas:
      variant === "contact" ? Joi.string().allow("") : Joi.string().required(),
    subject:
      variant === "contact" ? Joi.string().required() : Joi.string().allow(""),
  }).messages(DEFAULT_ERROR_MESSAGES)

  const { handleSubmit, control, register, errors } = useForm({
    resolver: joiResolver(FORM_SHAPE),
  })

  const onSubmit = (data) => {
    const { fullName, email, phone, message, type, pqe, areas, subject } = data

    setSubmitted(true)

    createContactEnquiry({
      variables: {
        fullName: fullName,
        email: email,
        phone: phone,
        message: message || "",
        type: variant === "contact" ? "contact" : "work",
        pqe: pqe || "",
        areas: areas || "",
        subject: subject || "",
      },
    })
  }

  if (submitted) {
    return (
      <Box mt={[20]}>
        <Text as="h3" mb={[10, null, 25]}>
          Thank you!
        </Text>
        <Text mb={[20, null, 37]} color="#2D2D2D">
          Thank you for contacting Aspen Legal. <br />
          We’ll aim to get back to you as soon as possible.
        </Text>

        <Button mr="auto" to="/">
          Back to the homepage
        </Button>
      </Box>
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsWrapper>
        <TextInput
          name="fullName"
          placeholder="Full name"
          ref={register}
          errors={errors}
        />
        <TextInput
          placeholder="Email"
          name="email"
          ref={register}
          errors={errors}
        />
        <TextInput
          placeholder="Phone"
          name="phone"
          type="phone"
          ref={register}
          errors={errors}
        />
        {variant === "contact" ? (
          <TextInput
            placeholder="Subject"
            name="subject"
            ref={register}
            errors={errors}
          />
        ) : (
          <>
            <TextInput
              placeholder="Years PQE"
              name="pqe"
              ref={register}
              errors={errors}
            />
            <TextInput
              placeholder="Areas of Practice"
              name="areas"
              ref={register}
              errors={errors}
            />
          </>
        )}
        <TextInput
          placeholder="Message"
          name="message"
          type="textarea"
          ref={register}
          errors={errors}
        />
        <Button
          type="submit"
          ml="auto"
          icon={<Icon name="send" w={21} h={18} color="white" />}
        >
          Submit Message
        </Button>
      </FormFieldsWrapper>
    </form>
  )
}

export default Form
