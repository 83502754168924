import styled from "styled-components"
import { device } from "breakpoints"

import AvenueLink from "ui/AvenueLink"

export const ButtonWrapper = styled(AvenueLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: white;
  padding: 10px 25px 12px 15px;
  margin-bottom: 20px;

  &:hover h3 {
    background-image: linear-gradient(to right, #fff, #fff);
    background-position: 0 95%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
  }
`

export const ButtonText = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-left: 10px;
    position: relative;
    top: 3px;
    word-break: break-all;
    color: #fff;

    @media ${device.desktop} {
      margin-left: 5px;
      top: 1px;
    }

    @media ${device.large} {
      font-size: 20px;
      margin-left: 10px;
    }
`

export const IconText = styled.div`
    display: flex;
    align-items: center;
`
