import React, { useState } from "react"
import gql from "graphql-tag"
import { Link } from "gatsby"
import { Box, Button, Text } from "ui"
import Form from "shared/Form"

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <Box
      borderBottom={["2px solid #E5E5E5", null, "none"]}
      pb={[40, null, 0]}
      pr={[null, null, 40]}
    >
      <Text fz={[20, null, 24]} color="#00A0A1" mb={[15, null, 25]}>
        Use the contact form below to get in touch with our team.
      </Text>

      <Text mb={[25, null, 46]} color="#2D2D2D">
        We'll aim to get back in touch within 48 hours.
      </Text>

      <Form
        variant="contact"
        setSubmitted={setSubmitted}
        submitted={submitted}
      />
    </Box>
  )
}

export default ContactForm
