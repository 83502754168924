import React, { useState } from "react"
import {
  ContactPageContainer,
  ContactUsWrapper,
  ContactUsDetail,
} from "./style"
import { useStaticQuery, graphql } from "gatsby"

import ContactForm from "./ContactForm"
import { Grid, Row, Col } from "ui/Grid"
import { InternalNav } from "screens/Internal/InternalNav"
import { SidebarButtons } from "screens/Internal/SidebarButtons"
import { Spacer } from "components/ui"

const ContactUs = ({ data }) => {
  const { emailEnquiry, phone } = data

  return (
    <ContactUsWrapper>
      <h5>Get in touch</h5>
      {phone && <ContactUsDetail>
        <strong>P</strong>
        <a href={`tel:${phone}`}> {phone}</a>
      </ContactUsDetail>}
     {emailEnquiry && <ContactUsDetail>
        <a href={`mailto:${emailEnquiry}`}>
          <strong>Email us</strong>
        </a>
      </ContactUsDetail>}
    </ContactUsWrapper>
  )
}

const Contact = ({ data }) => {
  const {
    wp: {
      siteOptions: { siteSettings },
    },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          siteOptions {
            siteSettings {
              phone
              emailEnquiry
            }
          }
        }
      }
    `
  )

  const {
    pageSettings: { relatedPages },
  } = data

  return (
    <ContactPageContainer>
      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <ContactForm />
          </Col>
          <Col xs={12} mdOffset={1} md={3}>
            <InternalNav>
              {siteSettings && <ContactUs data={siteSettings} />}
              <Spacer height={20} />
              {relatedPages?.length > 0 && (
                <SidebarButtons data={relatedPages} />
              )}
            </InternalNav>
          </Col>
        </Row>
      </Grid>
    </ContactPageContainer>
  )
}

export default Contact
